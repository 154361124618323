import React, { useState, useEffect } from "react";
import { ReplyFill, EyeFill, Envelope } from "react-bootstrap-icons";
import IconButton from "../../../../components/Button";
import TransactionDetailsModal from "../../../../components/Modals/TransactionModal";
import { CARD_BRANDS } from "../../../../utils/constants";

const TableRow = ({
  charge,
  isSuccess,
  isLoading,
  selectedTransactions,
  setSelectedTransactions,
  handleToggleRefundModal,
  handleRecieptEmailModal,
  handleToggleRefundDetailsModal,
}) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isTransactionSelected, setIsTransactionSelected] = useState(false);

  useEffect(() => {
    setIsTransactionSelected(false);
    setSelectedTransactions([]);
  }, [isSuccess]);

  const toggleTransactionDetailsModal = () => {
    setIsDetailsModalOpen(!isDetailsModalOpen);
  };

  const handleReceiptSelection = (e) => {
    const transactions = [...selectedTransactions];
    console.log(e,"xfsidfuisnfiusdn")
    if (e.target.checked) {
      setIsTransactionSelected(true);
      const data = {
        id: charge.id,
        amount: charge.amount / 100,
        created: new Date(charge.created * 1000).toLocaleDateString(),
        receipt_url: charge.receipt_url,
        description: charge.description,
      };
      transactions.push(data);
      setSelectedTransactions(transactions);
    } else {
      const selectedTx = transactions.find((tx) => tx.id === charge.id);
      const index = transactions.indexOf(selectedTx);
      transactions.splice(index, 1);
      setSelectedTransactions(transactions);
      setIsTransactionSelected(false);
    }
  };

  return (
    <>
      <tr>
        <td>
          <input
            type="checkbox"
            disabled={isLoading}
            checked={isTransactionSelected}
            onChange={(e) => handleReceiptSelection(e)}
          />
        </td>
        <td className="text-center">
          <img
            title={charge?.payment_method_details?.card?.brand}
            src={CARD_BRANDS[charge?.payment_method_details?.card?.brand]}
            alt={charge?.payment_method_details?.card?.brand}
            width={30}
          />
        </td>
        <td className="text-center">{charge?.payment_intent?.slice(21)}</td>
        <td>{charge?.invoice && charge?.metadata?.invoice_number}</td>
        <td className="text-center">${charge.amount / 100}</td>
        <td className="text-center">
          {charge.status === "failed" ? (
            <div>
              <span
                style={{ cursor: "pointer" }}
                className="badge badge-danger"
              >
                Failed
              </span>
              <br />
              <small className="mt-1">{charge?.failure_message}</small>
            </div>
          ) : (
            <span className="badge badge-success">Success</span>
          )}
        </td>
        <td className="text-center">
          {new Date(charge.created * 1000).toLocaleDateString()}
        </td>
        <td className="text-center">{charge?.description}</td>
        <td className="text-center">
          <IconButton
            title="View"
            className="mr-2 btn-sm"
            color="primary"
            icon={<EyeFill className="mr-2" />}
            onClick={() => toggleTransactionDetailsModal(charge)}
          />

          <IconButton
            title="Refund"
            className="btn-sm"
            color="danger"
            icon={<ReplyFill className="mr-2" />}
            disabled={
              charge.amount / 100 - charge.amount_refunded / 100 === 0 ||
              charge.amount / 100 - charge.amount_refunded / 100 <= 0.5 ||
              charge.status === "failed"
            }
            onClick={() => handleToggleRefundModal(charge)}
          />

          <IconButton
            title="Email"
            className="ml-2 btn-sm"
            color="primary"
            icon={<Envelope className="mr-2" />}
            onClick={() => {
              handleRecieptEmailModal(charge);
            }}
          />
        </td>
      </tr>

      <TransactionDetailsModal
        charge={charge}
        isOpen={isDetailsModalOpen}
        toggle={toggleTransactionDetailsModal}
        handleToggleRefundDetailsModal={handleToggleRefundDetailsModal}
      />
    </>
  );
};

export default TableRow;

